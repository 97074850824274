/* all the util custom classes should go here */
/* 

/** ------------------------------------------------**/

.btn-var-stripe {
  border: none;
  background-color: #5a44e4;
  color: #ffffff;
}

.btn-var-stripe:hover {
  border: none;
  background-color: #40329b;
  color: #ffffff;
}

/* HEADER MENU */

.header-menu-home {
  @apply bg-secondary-100 w-56 rounded-lg;
}

/* CARDS */

.card-item {
  @apply md:w-52 h-auto bg-base-100 shadow-xl hover:scale-[1.1] active:scale-100 transition duration-300 cursor-pointer;
}

/* HOME*/

.home-header-img {
  @apply w-full rounded-b-3xl h-28 md:h-72 md:rounded-none bg-cover bg-no-repeat bg-center;
}
.lend-header-img {
  @apply w-full  bg-opacity-50 rounded-b-3xl h-28 md:h-72 md:rounded-none bg-cover bg-no-repeat bg-center;
}
.bacground-overlay-home {
  @apply hero-overlay rounded-b-3xl md:rounded-none;
}

.flex-between {
  @apply flex gap-1 justify-between;
}

.home-section-styles {
  @apply flex gap-3 h-36 px-9 bg-primary-500 items-center justify-between md:px-24 md:h-96;
}
.section-paragraph {
  @apply text-sm font-bold font-primary capitalize md:text-4xl md:font-bold text-white;
}
/**RENT**/

.rent-header-img {
  @apply w-full rounded-b-3xl h-28 md:h-72 md:rounded-none bg-cover bg-no-repeat bg-center bg-secondary-900;
}

.lend-search-bar {
  @apply flex flex-col md:flex-row justify-center items-center gap-2 md:mt-0 -mt-4 md:justify-between w-full h-8 top-20 absolute md:pt-44 md:px-28;
}
.btn-outline:hover {
  @apply text-white !important;
}

.text-input {
  @apply input w-full text-gray-600 input-bordered;
}

/*Date Pciker calendar*/

.square {
  width: 7px;
  height: 7px;
  background-color: #f04747;
}
.square-container {
  width: 15px;
  height: 15px;
  background-color: #fff;
}
