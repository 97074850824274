/* all variants of custom shadow classes */

.shadow-var-sm {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.shadow-var-md {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.shadow-var-lg {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
}
