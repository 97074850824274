@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.border-gradient {
  border: 1px solid rgba(0, 0, 0, 0.032);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.active {
  background-color: #1e50ff;
  border-radius: 50%;
}

.pagination-item {
  border-radius: 0.375rem; /* rounded-md */
  background-color: #d2f5e1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  margin-right: 4px;
  margin-left: 4px;
  width: 2.25rem; /* w-9 */
  height: 2.25rem; /* h-9 */
  color: #075015;
}

.pagination-item-active {
  background-color: #22cc7a;
  color: #fff;
}

:root {
  --spacing: 1rem;
  --font-family: theme('fontFamily.primary');
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* ----- CSS Reset start ------ */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  scroll-behavior: smooth;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: theme('textColor.gray.700');
  font-family: theme('fontFamily.primary');
}

label {
  font-weight: 500;
  font-size: 14px;
  font-family: theme('fontFamily.primary');
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  overflow-x: hidden;
  font-size: 100%;
  font-family: var(--font-secondary);
  text-rendering: optimizeSpeed;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  user-select: none;
  -moz-window-dragging: no-drag;
}

/* Inherit fonts for inputs and buttons */
input,
textarea,
select {
  font: 'font-secondary';
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

div#root {
  min-height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
}

main {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  html {
    scroll-behavior: initial;
  }
}
/* ----- CSS Reset end ------ */

/* Custom Styling */

h1 {
  font-weight: bold;
  font-size: theme('fontSize.3xl'); /**24px**/
}

h2 {
  font-weight: bold;
  font-size: theme('fontSize.2xl'); /**18px**/
}

h3 {
  font-weight: bold;
  font-size: theme('fontSize.xl'); /**16px**/
}

h4 {
  font-weight: bold;
  font-size: theme('fontSize.lg'); /**14px**/
}
h5 {
  font-weight: bold;
  font-size: theme('fontSize.md'); /**12px**/
}
h6 {
  font-weight: bold;
  font-size: theme('fontSize.sm'); /**10px**/
}
input {
  background-color: #f6f6f6 !important;
}
input:focus {
  background-color: transparent !important;
}
textarea {
  background-color: #f6f6f6 !important;
}
textarea:focus {
  background-color: transparent !important;
}
/* 
.react-calendar {
  width: 400px;
  border-radius: 10px;
  padding: 2%;
  border: none;
  background: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation button {
  font-weight: bold;
  letter-spacing: 0.5px;
  background-color: #22cc7a;
  &:hover,
  &:enabled {
    background-color: none;
  }
}

.react-calendar__navigation__label__labelText {
  color: #22cc7a;
}

.react-calendar__month-view__weekdays {
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 3px;
}

.react-calendar__navigation {
  height: 35px;
  margin-bottom: 0.3em;
}

.react-calendar__tile--rangeStart {
  background: #5dcd97 !important;
  width: 50px;
  border-radius: 10px 0 0 10px;
  border-left: 2px solid #0e462b !important;
}

.react-calendar__tile--rangeEnd {
  background: #5dcd97 !important;
  width: 50px;
  border-radius: 0 10px 10px 0;
  border-right: 2px solid #0e462b !important;
}

.react-calendar__tile--rangeBothEnds {
  width: 50px;
  border-radius: 10px;
}

.react-calendar__tile--active {
  background: #22cc7a !important;
  color: white;
} */

/* react-calendar__tile
 react-calendar__tile--active
  react-calendar__tile--range
   react-calendar__tile--rangeStart
    react-calendar__tile--rangeEnd
   
      react-calendar__month-view__days__day */

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
